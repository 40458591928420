import React, { FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Input, Typography } from 'antd';
import {
  UserOutlined,
  IdcardOutlined,
  LockOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Page } from '../../../framework';
import PasswordHint from '../../../component/PasswordHint/PasswordHint';
import RequestError from '../../../component/RequestError/RequestError';
import { passwordRules } from '../../../helper/constants';
import { Div } from '../../../framework/Div/Div';
import { useAuthService } from '../../../service/auth.service';
import Menu from '../../../component/Menu/Menu';
import { RootState } from '../../../store/store';
import { setFlyoutOpen } from '../../../store/app.slice';
import { formFieldToLowerCase } from '../../../helper/form';
import { AUTH_ROUTES } from 'client/src/route/constant';
import * as analyticService from '../../../service/analytic.service';
import { IdentityTraits } from '../../../service/analytic.service';
import Password from 'antd/lib/input/Password';

import './Register.scss';

const { Title, Paragraph, Text } = Typography;

const RegisterPage: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authService = useAuthService();
  const [form] = Form.useForm();
  const { error } = useSelector((state: RootState) => state.app);
  const { userType } = useParams<{ userType: 'talent' | 'hirer' }>();
  const hasEmailExistError = error['auth/register']?.code === 'EMAIL_EXIST';
  const hasUsernameExistError =
    error['auth/register']?.code === 'USERNAME_EXIST';
  const hasInvalidPasswordError =
    error['auth/register']?.code === 'INVALID_PASSWORD';

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  const onFinish = async (values: any) => {
    await authService.register(values, userType);
    await analyticService.identify(values.username, {
      [IdentityTraits.TYPE]: 'talent',
    });
    history.push(`${AUTH_ROUTES.TALENT_LOGIN}?showMessage=true`);
  };

  return (
    <Page className='page-register'>
      <Helmet>
        <title>Create talent profile | HeyCast.Me</title>
        <meta
          property='og:title'
          content='Create talent profile | HeyCast.Me'
        />
        <meta
          name='description'
          content='Create a talent profile at HeyCast.Me - A job matching platform for acting talent'
        />
        <meta
          property='og:description'
          content='Create a talent profile at HeyCast.Me - A job matching platform for acting talent'
        />
        <meta
          property='og:image'
          content='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/confetti-thumbnail.jpg'
        />
      </Helmet>
      <Div className='mobile-view'>
        <Menu />
        <Form
          form={form}
          style={{ width: '100%' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Title level={3}>Create an account</Title>
          <Paragraph>
            Sign up and discover great amount of opportunities
          </Paragraph>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
              {
                type: 'email',
                message: 'This is not a valid email',
              },
            ]}
            {...(hasEmailExistError
              ? {
                  hasFeedback: true,
                  validateStatus: 'error',
                  help: 'Email already exists. Try login instead',
                }
              : {})}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder='Email'
              type='email'
              onBlur={() => formFieldToLowerCase(form, 'email')}
            />
          </Form.Item>
          <Form.Item
            name='name'
            rules={[
              {
                required: true,
                min: 4,
                message: 'Please input your name!',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder='Name' />
          </Form.Item>
          <Form.Item
            name='username'
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
              {
                pattern: /^[a-z0-9_]+$/,
                message: 'Only lowercase, number and underscore (_) is allowed',
              },
            ]}
            {...(hasUsernameExistError
              ? {
                  hasFeedback: true,
                  validateStatus: 'error',
                  help: 'This username is taken',
                }
              : {})}
          >
            <Input prefix={<IdcardOutlined />} placeholder='Username' />
          </Form.Item>
          <Div mb='l'>
            <Text type='secondary'>
              Username is used to creating a unique link to your profile, for
              example:{' '}
              <a
                href='https://heycast.me/t/annabella'
                target='_blank'
                rel='noreferrer'
              >
                https://heycast.me/t/annabella
              </a>
              . Username must be in lowercase, the only special character
              allowed is underscore (_)
            </Text>
          </Div>
          <Form.Item
            name='password'
            hasFeedback
            validateFirst
            rules={passwordRules}
            {...(hasInvalidPasswordError
              ? {
                  validateStatus: 'error',
                  help: error['auth/register']?.message,
                }
              : {})}
          >
            <Password prefix={<LockOutlined />} placeholder='Password' />
          </Form.Item>
          <PasswordHint />
          <Div mt='xxl'>
            <Paragraph type='secondary' style={{ fontSize: '14px' }}>
              By clicking &lsquo;Sign Up&lsquo;, you agree to the{' '}
              <a
                href='https://heycast.me/site-policy/terms'
                target='_blank'
                rel='noreferrer'
              >
                Terms
              </a>{' '}
              and acknowledge the{' '}
              <a
                href='https://heycast.me/site-policy/privacy'
                target='_blank'
                rel='noreferrer'
              >
                Privacy Policy
              </a>
              .
            </Paragraph>
          </Div>
          <Form.Item>
            <Button type='primary' htmlType='submit' block>
              Sign Up
            </Button>
            <RequestError requestKey='auth/register' />
            <Div mv='m'>
              Already have an account?{' '}
              <Link to={AUTH_ROUTES.TALENT_LOGIN}>Login here</Link>
            </Div>
          </Form.Item>
        </Form>
      </Div>
    </Page>
  );
};

export default RegisterPage;
