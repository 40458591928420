import React, { FunctionComponent, useEffect } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { LockOutlined, WarningFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Div, Page } from '../../../framework';
import { setFlyoutOpen, clearError } from '../../../store/app.slice';

import { useAuthService } from '../../../service/auth.service';
import RequestError from '../../../component/RequestError/RequestError';
import { RootState } from '../../../store/store';

import './ChangePassword.scss';
import { passwordRules } from '../../../helper/constants';
import { useHistory } from 'react-router-dom';
import PasswordHint from '../../../component/PasswordHint/PasswordHint';
import Password from 'antd/lib/input/Password';

const { Title, Paragraph, Text } = Typography;

const ChangePasswordPage: FunctionComponent = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const authService = useAuthService();
  const { error } = useSelector((state: RootState) => state.app);
  const hasLimitExceededError =
    error['auth/change-password']?.code === 'LIMIT_EXCEEDED_EXCEPTION';
  const hasOldPasswordIncorrectError =
    error['auth/change-password']?.code === 'INVALID_PASSWORD_EXCEPTION';

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));

    return () => {
      dispatch(clearError({ requestKey: 'auth/change-password' }));
    };
  }, []);

  const onFinish = async (values: any) => {
    await authService.changePassword(values, 'talent');
    history.push('/talent/jobs');
  };

  const renderErrorMessage = (code: string) => {
    switch (code) {
      case 'LIMIT_EXCEEDED_EXCEPTION':
        return 'You have failed to input the correct password for more than several times. Please try again later in a few minutes.';
    }
  };

  return (
    <Page className='page-change-password talent-page' align='center'>
      <Form
        form={form}
        style={{ width: '100%' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Title level={2} style={{ marginTop: '16px', marginBottom: '0' }}>
          Change Password
        </Title>
        <Paragraph>Changing new password</Paragraph>
        <Form.Item
          name='oldPass'
          hasFeedback
          rules={[{ required: true, message: 'Please input your Password!' }]}
          {...(hasOldPasswordIncorrectError
            ? {
                validateStatus: 'error',
                help: 'Your old password is incorrect',
              }
            : {})}
        >
          <Password
            prefix={<LockOutlined />}
            placeholder='Old Password'
            onPaste={(e) => e.preventDefault()}
          />
        </Form.Item>
        <Form.Item
          name='newPass'
          hasFeedback
          validateFirst
          rules={passwordRules}
        >
          <Password
            prefix={<LockOutlined />}
            placeholder='New Password'
            onPaste={(e) => e.preventDefault()}
          />
        </Form.Item>
        <Form.Item
          name='confirmPass'
          hasFeedback
          validateFirst
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPass') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
        >
          <Password
            prefix={<LockOutlined />}
            placeholder='Confirm Password'
            onPaste={(e) => e.preventDefault()}
          />
        </Form.Item>
        <PasswordHint />
        {hasLimitExceededError ? (
          <Div mb='m'>
            <Text type='danger'>
              <WarningFilled />
              {renderErrorMessage(error['auth/change-password'].code)}
            </Text>
          </Div>
        ) : null}
        <RequestError requestKey='auth/change-password' />
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            block
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </Page>
  );
};

export default ChangePasswordPage;
